define("ember-date-components/helpers/is-equal", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "@ember/component/helper"], function (_exports, _slicedToArray2, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isEqual = isEqual;
  function isEqual(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
      a = _ref2[0],
      b = _ref2[1];
    return a === b;
  }
  var _default = _exports.default = _helper.default.helper(isEqual);
});