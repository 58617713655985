define("ember-toggle/components/x-toggle-switch/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "GJGMIRH/",
    "block": "[[[10,\"input\"],[14,0,\"x-toggle\"],[15,\"checked\",[30,0,[\"toggled\"]]],[15,\"disabled\",[30,0,[\"disabled\"]]],[15,1,[30,0,[\"forId\"]]],[15,3,[30,0,[\"name\"]]],[15,\"onchange\",[28,[37,0],[[30,0],[30,0,[\"sendToggle\"]]],[[\"value\"],[\"target.checked\"]]]],[14,4,\"checkbox\"],[12],[13],[1,\"\\n\\n\"],[10,\"label\"],[15,\"for\",[30,0,[\"effectiveForId\"]]],[12],[1,\"\\n  \"],[10,0],[15,1,[29,[\"x-toggle-visual-\",[30,0,[\"forId\"]]]]],[14,\"role\",\"checkbox\"],[15,0,[29,[\"x-toggle-btn \",[30,0,[\"themeClass\"]],\" \",[30,0,[\"size\"]],[52,[30,0,[\"disabled\"]],\" x-toggle-disabled\"]]]],[15,\"aria-owns\",[30,0,[\"forId\"]]],[15,\"aria-checked\",[30,0,[\"toggled\"]]],[15,\"data-tg-on\",[30,0,[\"onLabel\"]]],[15,\"data-tg-off\",[30,0,[\"offLabel\"]]],[12],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"action\",\"if\"]]",
    "moduleName": "ember-toggle/components/x-toggle-switch/template.hbs",
    "isStrictMode": false
  });
});