define("ember-bootstrap/templates/components/bs-form/element/layout/vertical", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "+Fth59+n",
    "block": "[[[41,[30,0,[\"hasLabel\"]],[[[1,\"  \"],[46,[30,1],null,null,null],[1,\"\\n\"]],[]],null],[18,4,null],[1,\"\\n\"],[46,[30,2],null,null,null],[1,\"\\n\"],[46,[30,3],null,null,null]],[\"@labelComponent\",\"@errorsComponent\",\"@helpTextComponent\",\"&default\"],false,[\"if\",\"component\",\"yield\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-form/element/layout/vertical.hbs",
    "isStrictMode": false
  });
});