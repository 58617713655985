define("ember-bootstrap/templates/components/bs-navbar/toggle", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "94P8UQA0",
    "block": "[[[11,\"button\"],[24,4,\"button\"],[16,0,[29,[\"navbar-toggler\",\" \",[52,[30,0,[\"collapsed\"]],\"collapsed\"]]]],[17,1],[4,[38,1],[\"click\",[30,0,[\"handleClick\"]]],null],[12],[1,\"\\n\"],[41,[48,[30,2]],[[[1,\"    \"],[18,2,null],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,1],[14,0,\"navbar-toggler-icon\"],[12],[13],[1,\"\\n\"]],[]]],[13]],[\"&attrs\",\"&default\"],false,[\"if\",\"on\",\"has-block\",\"yield\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-navbar/toggle.hbs",
    "isStrictMode": false
  });
});