define("ember-bootstrap/templates/components/bs-accordion/item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "TpLQ3DQT",
    "block": "[[[11,0],[16,0,[29,[[52,[30,0,[\"disabled\"]],\"disabled\"],\" \",[30,0,[\"typeClass\"]],\" \",\"card\",\" \",[27]]]],[17,1],[12],[1,\"\\n\"],[41,[49,[30,2]],[[[1,\"    \"],[18,2,[[28,[37,3],null,[[\"title\",\"body\"],[[50,[30,0,[\"titleComponent\"]],0,null,[[\"collapsed\",\"disabled\",\"onClick\"],[[30,0,[\"collapsed\"]],[30,0,[\"disabled\"]],[28,[37,5],[[30,0],[33,6],[30,0,[\"value\"]]],null]]]],[50,[30,0,[\"bodyComponent\"]],0,null,[[\"collapsed\"],[[30,0,[\"collapsed\"]]]]]]]]]],[1,\"\\n\"]],[]],[[[46,[30,0,[\"titleComponent\"]],null,[[\"collapsed\",\"disabled\",\"onClick\"],[[30,0,[\"collapsed\"]],[30,0,[\"disabled\"]],[28,[37,5],[[30,0],[33,6],[30,0,[\"value\"]]],null]]],[[\"default\"],[[[[1,\"      \"],[1,[34,7]],[1,\"\\n\"]],[]]]]],[46,[30,0,[\"bodyComponent\"]],null,[[\"collapsed\"],[[30,0,[\"collapsed\"]]]],[[\"default\"],[[[[1,\"      \"],[18,2,null],[1,\"\\n\"]],[]]]]]],[]]],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"if\",\"has-block-params\",\"yield\",\"hash\",\"component\",\"action\",\"onClick\",\"title\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-accordion/item.hbs",
    "isStrictMode": false
  });
});