define("ember-gestures/components/async-element", ["exports", "@ember/object", "ember-gestures/components/gesture-element"], function (_exports, _object, _gestureElement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**!
   *
   * Provides the ability to easily build a
   * gesture-ful async-button implementation
   *
   */
  var _default = _exports.default = _gestureElement.default.extend({
    classNameBindings: ['actionState'],
    actionState: 'default',
    isPending: (0, _object.computed)('actionState', function () {
      return this.get('actionState') === 'pending';
    }),
    _getParams: function _getParams(actionName) {
      var _this = this;
      var actionArguments = this._super(actionName);
      var callbackHandler = function callbackHandler(promise) {
        _this.set('promise', promise);
        _this.set('actionState', 'pending');
      };
      actionArguments.splice(1, 0, callbackHandler);
      return actionArguments;
    },
    __observePromiseState: (0, _object.observer)('promise', function promiseTheComponentState() {
      var _this2 = this;
      (0, _object.get)(this, 'promise').then(function () {
        if (!_this2.isDestroyed) {
          _this2.set('actionState', 'fulfilled');
        }
      }).catch(function () {
        if (!_this2.isDestroyed) {
          _this2.set('actionState', 'rejected');
        }
      });
    })
  });
});