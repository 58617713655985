define("ember-bootstrap/templates/components/bs-form/element/feedback-icon", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "gD1R1blh",
    "block": "[[[41,[30,0,[\"show\"]],[[[1,\"  \"],[10,1],[15,0,[29,[\"form-control-feedback \",[30,0,[\"iconName\"]]]]],[14,\"aria-hidden\",\"true\"],[12],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-form/element/feedback-icon.hbs",
    "isStrictMode": false
  });
});