define("ember-bootstrap/templates/components/bs-modal/header/close", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "H5UFuVKQ",
    "block": "[[[11,\"button\"],[24,4,\"button\"],[24,\"aria-label\",\"Close\"],[24,0,\"close\"],[17,1],[4,[38,0],[\"click\",[30,0,[\"handleClick\"]]],null],[12],[1,\"\\n  \"],[10,1],[14,\"aria-hidden\",\"true\"],[12],[1,\"×\"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[\"on\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-modal/header/close.hbs",
    "isStrictMode": false
  });
});