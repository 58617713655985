define("ember-bootstrap/templates/components/bs-form/element/help-text", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "rp+Nn5qe",
    "block": "[[[11,0],[16,1,[30,1]],[24,0,\"form-text\"],[17,2],[12],[1,\"\\n  \"],[1,[30,3]],[1,\"\\n\"],[13]],[\"@id\",\"&attrs\",\"@text\"],false,[]]",
    "moduleName": "ember-bootstrap/templates/components/bs-form/element/help-text.hbs",
    "isStrictMode": false
  });
});