define("ember-bootstrap/templates/components/bs-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "QUhFrbw2",
    "block": "[[[11,\"button\"],[16,\"disabled\",[30,0,[\"__disabled\"]]],[16,4,[30,0,[\"buttonType\"]]],[16,0,[29,[\"btn \",[52,[30,0,[\"active\"]],\"active\"],\" \",[52,[30,0,[\"block\"]],\"btn-block\"],\" \",[30,0,[\"sizeClass\"]],\" \",[30,0,[\"typeClass\"]]]]],[17,1],[4,[38,1],[\"click\",[30,0,[\"handleClick\"]]],null],[12],[1,\"\\n  \"],[41,[30,0,[\"icon\"]],[[[10,\"i\"],[15,0,[30,0,[\"icon\"]]],[12],[13],[1,\" \"]],[]],null],[1,[30,0,[\"text\"]]],[18,2,[[28,[37,3],null,[[\"isFulfilled\",\"isPending\",\"isRejected\",\"isSettled\"],[[30,0,[\"isFulfilled\"]],[30,0,[\"isPending\"]],[30,0,[\"isRejected\"]],[30,0,[\"isSettled\"]]]]]]],[1,\"\\n\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"if\",\"on\",\"yield\",\"hash\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-button.hbs",
    "isStrictMode": false
  });
});