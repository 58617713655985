define("ember-bootstrap/templates/components/bs-carousel/slide", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "c1a0KXK5",
    "block": "[[[11,0],[16,0,[29,[\"carousel-item\",\" \",[27],\" \",[52,[30,0,[\"active\"]],\"active\"],\" \",[52,[30,0,[\"left\"]],\"carousel-item-left\"],\" \",[27],\" \",[52,[30,0,[\"next\"]],\"carousel-item-next\"],\" \",[27],\" \",[52,[30,0,[\"prev\"]],\"carousel-item-prev\"],\" \",[27],\" \",[52,[30,0,[\"right\"]],\"carousel-item-right\"],\" \",[27]]]],[17,1],[4,[38,1],[[30,0],\"_element\"],null],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"if\",\"ref\",\"yield\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-carousel/slide.hbs",
    "isStrictMode": false
  });
});