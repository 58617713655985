define("ember-date-components/templates/components/date-picker-navigation", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "pMtpzyND",
    "block": "[[[11,\"button\"],[24,0,\"date-picker__header__button date-picker__header__button--previous\"],[24,\"data-test-date-picker-goto-previous-month\",\"\"],[24,4,\"button\"],[4,[38,0],[[30,0],\"gotoPreviousMonth\"],null],[12],[1,\"\\n  <\\n\"],[13],[1,\"\\n\\n\"],[11,\"button\"],[24,0,\"date-picker__header__button date-picker__header__button--next\"],[24,\"data-test-date-picker-goto-next-month\",\"\"],[24,4,\"button\"],[4,[38,0],[[30,0],\"gotoNextMonth\"],null],[12],[1,\"\\n  >\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"date-picker__header__title\"],[15,\"data-test-date-picker-month\",[28,[37,1],[[33,2],\"MM\"],null]],[15,\"data-test-date-picker-year\",[28,[37,1],[[33,2],\"YYYY\"],null]],[12],[1,\"\\n\\n  \"],[1,[28,[35,3],null,[[\"minDate\",\"maxDate\",\"currentMonth\",\"disableMonthPicker\",\"disableYearPicker\",\"availableYearOffset\",\"gotoMonth\"],[[33,4],[33,5],[33,2],[33,6],[33,7],[33,8],[28,[37,0],[[30,0],\"gotoMonth\"],null]]]]],[1,\"\\n\\n\"],[13]],[],false,[\"action\",\"moment-format\",\"currentMonth\",\"date-picker-month-year-select\",\"minDate\",\"maxDate\",\"disableMonthPicker\",\"disableYearPicker\",\"availableYearOffset\"]]",
    "moduleName": "ember-date-components/templates/components/date-picker-navigation.hbs",
    "isStrictMode": false
  });
});