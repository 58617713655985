define("ember-bootstrap/templates/components/bs-modal/header/title", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "UTArMlSH",
    "block": "[[[1,\"  \"],[11,\"h5\"],[24,0,\"modal-title\"],[17,1],[12],[1,\"\\n    \"],[18,2,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-modal/header/title.hbs",
    "isStrictMode": false
  });
});