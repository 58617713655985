define("ember-bootstrap/templates/components/bs-nav", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "0KdzwQ/6",
    "block": "[[[11,\"ul\"],[16,0,[29,[\"nav \",[30,0,[\"typeClass\"]],\" \",[30,0,[\"additionalClass\"]],\" \",[52,[30,0,[\"justified\"]],\"nav-justified\"],\" \",[52,[30,0,[\"stacked\"]],\"flex-column\"],\" \",[52,[30,0,[\"fill\"]],\"nav-fill\"]]]],[17,1],[12],[1,\"\\n  \"],[18,2,[[28,[37,2],null,[[\"item\",\"link-to\",\"linkTo\",\"dropdown\"],[[50,[30,0,[\"itemComponent\"]],0,null,null],[50,[30,0,[\"linkToComponent\"]],0,null,null],[50,[30,0,[\"linkToComponent\"]],0,null,null],[50,[30,0,[\"dropdownComponent\"]],0,null,[[\"inNav\",\"htmlTag\"],[true,\"li\"]]]]]]]],[1,\"\\n\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"if\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-nav.hbs",
    "isStrictMode": false
  });
});