define("ember-bootstrap/templates/components/bs-collapse", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "7gsc6vYK",
    "block": "[[[11,0],[16,0,[29,[[52,[30,0,[\"collapse\"]],\"collapse\"],\" \",[52,[30,0,[\"collapsing\"]],\"collapsing\"],\" \",[52,[30,0,[\"showContent\"]],\"show\"]]]],[17,1],[4,[38,1],[[30,0],\"_element\"],null],[4,[38,2],[[30,0,[\"cssStyle\"]]],null],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"&default\"],false,[\"if\",\"ref\",\"style\",\"yield\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-collapse.hbs",
    "isStrictMode": false
  });
});