define("ember-bootstrap/templates/components/bs-dropdown", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "trhpOCGg",
    "block": "[[[44,[[50,[28,[37,2],[[30,0,[\"htmlTag\"]]],null],0,null,[[\"tagName\"],[[30,0,[\"htmlTag\"]]]]]],[[[1,\"  \"],[8,[30,1],[[16,0,[29,[[30,0,[\"containerClass\"]],\" \",[52,[30,0,[\"inNav\"]],\"nav-item\"],\" \",[52,[30,0,[\"isOpen\"]],\"show\"]]]],[17,2]],null,[[\"default\"],[[[[1,\"\\n    \"],[18,4,[[28,[37,5],null,[[\"button\",\"toggle\",\"menu\",\"toggleDropdown\",\"openDropdown\",\"closeDropdown\",\"isOpen\"],[[50,[30,0,[\"buttonComponent\"]],0,null,[[\"isOpen\",\"onClick\",\"onKeyDown\",\"registerChildElement\",\"unregisterChildElement\"],[[30,0,[\"isOpen\"]],[30,0,[\"toggleDropdown\"]],[30,0,[\"handleKeyEvent\"]],[30,0,[\"registerChildElement\"]],[30,0,[\"unregisterChildElement\"]]]]],[50,[30,0,[\"toggleComponent\"]],0,null,[[\"isOpen\",\"inNav\",\"onClick\",\"onKeyDown\",\"registerChildElement\",\"unregisterChildElement\"],[[30,0,[\"isOpen\"]],[33,6],[30,0,[\"toggleDropdown\"]],[30,0,[\"handleKeyEvent\"]],[30,0,[\"registerChildElement\"]],[30,0,[\"unregisterChildElement\"]]]]],[50,[30,0,[\"menuComponent\"]],0,null,[[\"isOpen\",\"direction\",\"inNav\",\"toggleElement\",\"registerChildElement\",\"unregisterChildElement\"],[[30,0,[\"isOpen\"]],[30,0,[\"direction\"]],[30,3],[30,0,[\"toggleElement\"]],[30,0,[\"registerChildElement\"]],[30,0,[\"unregisterChildElement\"]]]]],[30,0,[\"toggleDropdown\"]],[30,0,[\"openDropdown\"]],[30,0,[\"closeDropdown\"]],[30,0,[\"isOpen\"]]]]]]],[1,\"\\n\"],[41,[30,0,[\"isOpen\"]],[[[1,\"      \"],[1,[28,[35,7],[\"keydown\",[30,0,[\"handleKeyEvent\"]]],null]],[1,\"\\n      \"],[1,[28,[35,7],[\"click\",[30,0,[\"closeHandler\"]]],[[\"capture\"],[true]]]],[1,\"\\n      \"],[1,[28,[35,7],[\"keyup\",[30,0,[\"closeHandler\"]]],null]],[1,\"\\n\"]],[]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]],[\"Tag\",\"&attrs\",\"@inNav\",\"&default\"],false,[\"let\",\"component\",\"-element\",\"if\",\"yield\",\"hash\",\"inNav\",\"on-document\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-dropdown.hbs",
    "isStrictMode": false
  });
});