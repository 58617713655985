define("ember-date-components/helpers/is-equal-month", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "@ember/component/helper", "moment"], function (_exports, _slicedToArray2, _helper, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isEqualMonth = isEqualMonth;
  function isEqualMonth(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
      m1 = _ref2[0],
      m2 = _ref2[1];
    if (!_moment.default.isMoment(m1) || !_moment.default.isMoment(m2)) {
      return false;
    }
    return m1.format('YYYY-MM') === m2.format('YYYY-MM');
  }
  var _default = _exports.default = _helper.default.helper(isEqualMonth);
});