define("ember-bootstrap/templates/components/bs-dropdown/toggle", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "HHaWPOPR",
    "block": "[[[11,3],[24,6,\"#\"],[16,0,[29,[\"dropdown-toggle \",[52,[30,1],\"nav-link\"]]]],[16,\"aria-expanded\",[30,0,[\"aria-expanded\"]]],[24,\"role\",\"button\"],[17,2],[4,[38,1],[\"keydown\",[30,0,[\"handleKeyDown\"]]],null],[4,[38,1],[\"click\",[30,0,[\"handleClick\"]]],null],[4,[38,2],[[30,3],\"toggle\"],null],[4,[38,3],[[30,4],\"toggle\"],null],[12],[1,\"\\n  \"],[18,5,null],[1,\"\\n\"],[13]],[\"@inNav\",\"&attrs\",\"@registerChildElement\",\"@unregisterChildElement\",\"&default\"],false,[\"if\",\"on\",\"did-insert\",\"will-destroy\",\"yield\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-dropdown/toggle.hbs",
    "isStrictMode": false
  });
});