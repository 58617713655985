define("@ember-decorators/object/index", ["exports", "@babel/runtime/helpers/esm/createForOfIteratorHelper", "@ember/debug", "@ember/object", "@ember/object/computed", "@ember/object/events", "@ember/object/observers", "@ember-decorators/utils/decorator"], function (_exports, _createForOfIteratorHelper2, _debug, _object, _computed, _events, _observers, _decorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unobserves = _exports.on = _exports.off = _exports.observes = void 0;
  /**
    Triggers the target function when the dependent properties have changed. Note,
    `@observes` _must_ be used on EmberObject based classes only, otherwise there
    may be subtle issues and breakage.
  
    ```javascript
    import { observes } from '@ember-decorators/object';
  
    class Foo {
      @observes('foo')
      bar() {
        //...
      }
    }
    ```
  
    @function
    @param {...String} propertyNames - Names of the properties that trigger the function
   */
  var observes = _exports.observes = (0, _decorator.decoratorWithRequiredParams)(function (target, key, desc, params) {
    (true && !(desc && typeof desc.value === 'function') && (0, _debug.assert)('The @observes decorator must be applied to functions', desc && typeof desc.value === 'function'));
    (true && !(target instanceof _object.default) && (0, _debug.assert)("You attempted to use @observes on ".concat(target.constructor.name, "#").concat(key, ", which does not extend from EmberObject. Unfortunately this does not work with stage 1 decorator transforms, and will break in subtle ways. You must rewrite your class to extend from EmberObject."), target instanceof _object.default));
    var _iterator = (0, _createForOfIteratorHelper2.default)(params),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var path = _step.value;
        (0, _computed.expandProperties)(path, function (expandedPath) {
          (0, _observers.addObserver)(target, expandedPath, null, key);
        });
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
    return desc;
  }, 'observes');

  /**
    Removes observers from the target function.
  
    ```javascript
    import { observes, unobserves } from '@ember-decorators/object';
  
    class Foo {
      @observes('foo')
      bar() {
        //...
      }
    }
  
    class Bar extends Foo {
      @unobserves('foo') bar;
    }
    ```
  
    @function
    @param {...String} propertyNames - Names of the properties that no longer trigger the function
   */
  var unobserves = _exports.unobserves = (0, _decorator.decoratorWithRequiredParams)(function (target, key, desc, params) {
    var _iterator2 = (0, _createForOfIteratorHelper2.default)(params),
      _step2;
    try {
      for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
        var path = _step2.value;
        (0, _computed.expandProperties)(path, function (expandedPath) {
          (0, _observers.removeObserver)(target, expandedPath, null, key);
        });
      }
    } catch (err) {
      _iterator2.e(err);
    } finally {
      _iterator2.f();
    }
    return desc;
  }, 'unobserves');

  /**
    Adds an event listener to the target function.
  
    ```javascript
    import { on } from '@ember-decorators/object';
  
    class Foo {
      @on('fooEvent', 'barEvent')
      bar() {
        //...
      }
    }
    ```
  
    @function
    @param {...String} eventNames - Names of the events that trigger the function
   */
  var on = _exports.on = (0, _decorator.decoratorWithRequiredParams)(function (target, key, desc, params) {
    (true && !(desc && typeof desc.value === 'function') && (0, _debug.assert)('The @on decorator must be applied to functions', desc && typeof desc.value === 'function'));
    var _iterator3 = (0, _createForOfIteratorHelper2.default)(params),
      _step3;
    try {
      for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
        var eventName = _step3.value;
        (0, _events.addListener)(target, eventName, null, key);
      }
    } catch (err) {
      _iterator3.e(err);
    } finally {
      _iterator3.f();
    }
    return desc;
  }, 'on');

  /**
    Removes an event listener from the target function.
  
    ```javascript
    import { on, off } from '@ember-decorators/object';
  
    class Foo {
      @on('fooEvent', 'barEvent')
      bar() {
        //...
      }
    }
  
    class Bar extends Foo {
      @off('fooEvent', 'barEvent') bar;
    }
    ```
  
    @function
    @param {...String} eventNames - Names of the events that no longer trigger the function
   */
  var off = _exports.off = (0, _decorator.decoratorWithRequiredParams)(function (target, key, desc, params) {
    var _iterator4 = (0, _createForOfIteratorHelper2.default)(params),
      _step4;
    try {
      for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
        var eventName = _step4.value;
        (0, _events.removeListener)(target, eventName, null, key);
      }
    } catch (err) {
      _iterator4.e(err);
    } finally {
      _iterator4.f();
    }
    return desc;
  }, 'off');
});