define("ember-bootstrap/templates/components/bs-form/element/layout/horizontal", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "vLCZL/c0",
    "block": "[[[41,[30,0,[\"hasLabel\"]],[[[1,\"  \"],[46,[30,1],null,[[\"labelClass\"],[[30,2]]],null],[1,\"\\n  \"],[10,0],[15,0,[30,0,[\"horizontalInputGridClass\"]]],[12],[1,\"\\n    \"],[18,5,null],[1,\"\\n\"],[1,\"    \"],[46,[30,3],null,null,null],[1,\"\\n    \"],[46,[30,4],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[15,0,[29,[[30,0,[\"horizontalInputGridClass\"]],\" \",[30,0,[\"horizontalInputOffsetGridClass\"]]]]],[12],[1,\"\\n    \"],[18,5,null],[1,\"\\n\"],[1,\"    \"],[46,[30,3],null,null,null],[1,\"\\n    \"],[46,[30,4],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"@labelComponent\",\"@horizontalLabelGridClass\",\"@errorsComponent\",\"@helpTextComponent\",\"&default\"],false,[\"if\",\"component\",\"yield\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-form/element/layout/horizontal.hbs",
    "isStrictMode": false
  });
});