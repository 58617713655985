define("ember-bootstrap/templates/components/bs-form/element/label", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "ZdXsMJzK",
    "block": "[[[1,\"  \"],[10,\"label\"],[15,0,[29,[[52,[30,0,[\"invisibleLabel\"]],\"sr-only\"],\" \",[30,0,[\"labelClass\"]],\" \",[52,[30,0,[\"isHorizontalAndNotCheckbox\"]],\"col-form-label\"],\" \",[52,[30,0,[\"isCheckbox\"]],\"form-check-label\"],\" \",[30,0,[\"sizeClass\"]]]]],[15,\"for\",[30,1]],[12],[1,\"\\n\"],[41,[48,[30,3]],[[[1,\"      \"],[18,3,null],[1,\"\\n\"]],[]],null],[1,\"    \"],[1,[30,2]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@formElementId\",\"@label\",\"&default\"],false,[\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-form/element/label.hbs",
    "isStrictMode": false
  });
});