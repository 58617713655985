define("ember-gestures/services/-gestures", ["exports", "@ember/object", "@ember/application", "@ember/service", "rsvp", "ember-gestures/utils/string/dasherized-to-camel", "ember-gestures/utils/string/capitalize-word"], function (_exports, _object, _application, _service, _rsvp, _dasherizedToCamel, _capitalizeWord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Promise = _rsvp.default.Promise,
    defer = _rsvp.default.defer;
  var _default = _exports.default = _service.default.extend({
    _recognizers: null,
    _fastboot: (0, _object.computed)(function () {
      var owner = (0, _application.getOwner)(this);
      return owner.lookup('service:fastboot');
    }),
    retrieve: function retrieve(names) {
      var _this = this;
      var promises = names.map(function (name) {
        return _this.lookupRecognizer(name);
      });
      return _rsvp.default.all(promises);
    },
    createRecognizer: function createRecognizer(name, details) {
      var eventName = (0, _dasherizedToCamel.default)(details.eventName || name).toLowerCase();
      var gesture = (0, _capitalizeWord.default)(details.recognizer);
      var options = details.options || {};
      options.event = eventName;
      options.name = name;
      var Recognizer = new Hammer[gesture](options);
      Recognizer.initialize = defer();
      this.set("_recognizers.".concat(name), Recognizer);
      return Recognizer;
    },
    setupRecognizer: function setupRecognizer(name, details) {
      var _this2 = this;
      if (this.get('_fastboot.isFastBoot')) {
        return;
      }
      return Promise.resolve(this.createRecognizer(name, details))

      // includes
      .then(function (Recognizer) {
        if (details.include) {
          var included = details.include.map(function (name) {
            return _this2.__speedyLookupRecognizer(name);
          });
          return _rsvp.default.all(included).then(function (recognizers) {
            Recognizer.recognizeWith(recognizers);
            return Recognizer;
          });
        }
        return Recognizer;
      })

      // excludes
      .then(function (Recognizer) {
        if (details.exclude) {
          var excluded = details.exclude.map(function (name) {
            return _this2.__speedyLookupRecognizer(name);
          });
          return _rsvp.default.all(excluded).then(function (recognizers) {
            Recognizer.requireFailure(recognizers);
            Recognizer.exclude = recognizers;
            Recognizer.initialize.resolve(Recognizer);
            return Recognizer;
          });
        } else {
          Recognizer.exclude = [];
          Recognizer.initialize.resolve(Recognizer);
          return Recognizer;
        }
      });
    },
    __speedyLookupRecognizer: function __speedyLookupRecognizer(name) {
      var recognizer = this.get("_recognizers.".concat(name));
      if (recognizer) {
        return recognizer;
      }
      var path = "ember-gesture:recognizers/".concat(name);
      var details = (0, _application.getOwner)(this).factoryFor(path);
      if (details) {
        return this.setupRecognizer(name, details.class);
      }
      return Promise.reject(new Error("ember-gestures/recognizers/".concat(name, " was not found. You can scaffold this recognizer with 'ember g recognizer ").concat(name, "'")));
    },
    lookupRecognizer: function lookupRecognizer(name) {
      var recognizer = this.get("_recognizers.".concat(name));
      if (recognizer) {
        return recognizer.initialize.promise.then(function (recognizer) {
          return recognizer;
        });
      }
      var path = "ember-gesture:recognizers/".concat(name);
      var details = (0, _application.getOwner)(this).factoryFor(path);
      if (details) {
        return this.setupRecognizer(name, details.class);
      }
      return Promise.reject(new Error("ember-gestures/recognizers/".concat(name, " was not found. You can scaffold this recognizer with 'ember g recognizer ").concat(name, "'")));
    },
    init: function init() {
      this._super();
      this._recognizers = {};
    }
  });
});