define("ember-bootstrap/templates/components/bs-button-group", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "iXzfsXch",
    "block": "[[[11,0],[16,0,[29,[[52,[30,0,[\"vertical\"]],\"btn-group-vertical\",\"btn-group\"],\" \",[30,0,[\"sizeClass\"]],\" \",[52,[30,0,[\"justified\"]],[27]]]]],[17,1],[12],[1,\"\\n  \"],[18,4,[[28,[37,2],null,[[\"button\"],[[50,[30,0,[\"buttonComponent\"]],0,null,[[\"buttonGroupType\",\"groupValue\",\"onClick\"],[[30,2],[30,3],[30,0,[\"buttonPressed\"]]]]]]]]]],[1,\"\\n\\n\"],[13]],[\"&attrs\",\"@type\",\"@value\",\"&default\"],false,[\"if\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-button-group.hbs",
    "isStrictMode": false
  });
});