define("ember-bootstrap/templates/components/bs-navbar/content", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "nA6xKxy8",
    "block": "[[[8,[39,0],[[24,0,\"navbar-collapse\"],[17,1]],[[\"@collapsed\",\"@onHidden\",\"@onShown\"],[[30,2],[30,3],[30,4]]],[[\"default\"],[[[[1,\"\\n  \"],[18,5,null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"&attrs\",\"@collapsed\",\"@onHidden\",\"@onShown\",\"&default\"],false,[\"bs-collapse\",\"yield\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-navbar/content.hbs",
    "isStrictMode": false
  });
});