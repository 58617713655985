define("ember-bootstrap/templates/components/bs-form/element/layout/vertical/checkbox", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "pVQRtGtv",
    "block": "[[[1,\"  \"],[10,0],[14,0,\"form-check\"],[12],[1,\"\\n    \"],[18,4,null],[1,\"\\n    \"],[46,[30,1],null,null,null],[1,\"\\n    \"],[46,[30,2],null,null,null],[1,\"\\n    \"],[46,[30,3],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@labelComponent\",\"@errorsComponent\",\"@helpTextComponent\",\"&default\"],false,[\"yield\",\"component\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-form/element/layout/vertical/checkbox.hbs",
    "isStrictMode": false
  });
});