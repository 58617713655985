define("ember-toggle/components/x-toggle/component", ["exports", "@ember/object/computed", "@ember/component", "@ember/object", "ember-toggle/components/x-toggle/template"], function (_exports, _computed, _component, _object, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    classNames: ['x-toggle-component'],
    classNameBindings: ['focused:x-toggle-focused'],
    attributeBindings: ['tabindex'],
    tabindex: '0',
    focused: false,
    disabled: false,
    name: 'default',
    onLabel: 'On',
    offLabel: 'Off',
    value: false,
    // private
    toggled: (0, _computed.readOnly)('value'),
    forId: (0, _object.computed)(function () {
      return this.get('elementId') + '-x-toggle';
    }),
    keyPress: function keyPress(event) {
      // spacebar: 32
      if (event.which === 32) {
        var value = this.get('value');
        this.toggleSwitch(!value);
        event.preventDefault();
      }
    },
    focusIn: function focusIn() {
      this.set('focused', true);
    },
    focusOut: function focusOut() {
      this.set('focused', false);
    },
    toggleSwitch: function toggleSwitch(value) {
      var onToggle = this.get('onToggle');
      var disabled = this.get('disabled');
      if (!disabled && value !== this.get('value') && typeof onToggle === 'function') {
        var name = this.get('name');
        onToggle(value, name);

        // The click on input/label will toggle the input unconditionally.
        // Input state has to be updated manually to prevent it going out of
        // sync in case the action didn't update value.
        var checkbox = this.element.querySelector('.x-toggle');
        var newValue = this.get('value');
        if (checkbox.checked !== newValue) {
          checkbox.checked = newValue;
        }
      }
    },
    actions: {
      sendToggle: function sendToggle(value) {
        this.toggleSwitch(value);
      }
    }
  });
});