define("ember-bootstrap/templates/components/bs-modal/footer", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "4TgV6Q4u",
    "block": "[[[11,\"form\"],[24,0,\"modal-footer\"],[17,1],[4,[38,0],[\"submit\",[30,0,[\"handleSubmit\"]]],null],[12],[1,\"\\n\"],[41,[48,[30,5]],[[[1,\"    \"],[18,5,null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"hasSubmitButton\"]],[[[1,\"      \"],[46,[30,0,[\"buttonComponent\"]],null,[[\"onClick\"],[[30,2]]],[[\"default\"],[[[[1,[30,0,[\"closeTitle\"]]]],[]]]]],[1,\"\\n      \"],[46,[30,0,[\"buttonComponent\"]],null,[[\"type\",\"onClick\",\"_disabled\"],[[30,0,[\"submitButtonType\"]],[30,3],[30,0,[\"submitDisabled\"]]]],[[\"default\"],[[[[1,[30,4]]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[46,[30,0,[\"buttonComponent\"]],null,[[\"type\",\"onClick\"],[\"primary\",[30,2]]],[[\"default\"],[[[[1,[30,0,[\"closeTitle\"]]]],[]]]]],[1,\"\\n\"]],[]]]],[]]],[1,\"  \\n\"],[13]],[\"&attrs\",\"@onClose\",\"@onSubmit\",\"@submitTitle\",\"&default\"],false,[\"on\",\"if\",\"has-block\",\"yield\",\"component\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-modal/footer.hbs",
    "isStrictMode": false
  });
});