define("ember-bootstrap/templates/components/bs-modal/body", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "Il/7Zrp4",
    "block": "[[[11,0],[24,0,\"modal-body\"],[17,1],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-modal/body.hbs",
    "isStrictMode": false
  });
});