define("ember-bootstrap/templates/components/bs-form/element/control/checkbox", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "dD67P8WK",
    "block": "[[[11,\"input\"],[24,4,\"checkbox\"],[16,1,[30,1]],[16,\"disabled\",[30,0,[\"disabled\"]]],[16,\"readonly\",[30,0,[\"readonly\"]]],[16,\"aria-describedby\",[30,0,[\"ariaDescribedBy\"]]],[16,\"checked\",[30,0,[\"value\"]]],[16,0,[29,[\"form-check-input\",\" \",[30,0,[\"formValidationClass\"]]]]],[17,2],[4,[38,0],[\"click\",[30,0,[\"handleClick\"]]],null],[12],[13],[1,\"\\n\\n\"]],[\"@id\",\"&attrs\"],false,[\"on\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-form/element/control/checkbox.hbs",
    "isStrictMode": false
  });
});