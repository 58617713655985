define("ember-date-components/templates/components/date-picker-inline", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "TgIzS+cz",
    "block": "[[[10,0],[15,0,[29,[\"date-picker date-picker--inline \",[52,[33,1],\" date-picker--options\"]]]],[15,5,[36,2]],[12],[1,\"\\n  \"],[10,0],[14,0,\"date-picker__calendar\"],[12],[1,\"\\n\\n    \"],[1,[28,[35,3],null,[[\"currentMonth\",\"minDate\",\"maxDate\",\"disableMonthPicker\",\"disableYearPicker\",\"availableYearOffset\",\"class\",\"updateMonth\"],[[33,4],[33,5],[33,6],[33,7],[33,8],[33,9],\"date-picker__header\",[28,[37,10],[[30,0],\"gotoMonth\"],null]]]]],[1,\"\\n\\n    \"],[1,[28,[35,11],null,[[\"month\",\"selectedDates\",\"selectDate\",\"minDate\",\"maxDate\",\"startWeekOnSunday\"],[[33,4],[33,12],[28,[37,10],[[30,0],\"selectDate\"],null],[33,5],[33,6],[33,13]]]]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[33,1],[[[1,\"    \"],[10,0],[14,0,\"date-picker__options\"],[12],[1,\"\\n\"],[42,[28,[37,15],[[28,[37,15],[[33,16]],null]],null],null,[[[1,\"        \"],[11,\"button\"],[16,0,[29,[\"date-picker__options__button \",[30,1,[\"classes\"]]]]],[24,4,\"button\"],[4,[38,10],[[30,0],[30,1,[\"action\"]],[30,1,[\"actionValue\"]]],null],[12],[1,\"\\n          \"],[1,[30,1,[\"label\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"option\"],false,[\"if\",\"options\",\"translateX\",\"date-picker-navigation\",\"currentMonth\",\"minDate\",\"maxDate\",\"disableMonthPicker\",\"disableYearPicker\",\"availableYearOffset\",\"action\",\"date-picker-month\",\"selectedDates\",\"startWeekOnSunday\",\"each\",\"-track-array\",\"_options\"]]",
    "moduleName": "ember-date-components/templates/components/date-picker-inline.hbs",
    "isStrictMode": false
  });
});