define("ember-bootstrap/templates/components/bs-dropdown/menu/divider", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "4N5BmDJg",
    "block": "[[[10,0],[14,0,\"dropdown-divider\"],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-dropdown/menu/divider.hbs",
    "isStrictMode": false
  });
});