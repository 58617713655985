define("ember-concurrency/-task-state-mixin", ["exports", "@ember/object/computed", "@ember/object/mixin", "@ember/object"], function (_exports, _computed, _mixin, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var alias = _object.computed.alias;

  // this is a mixin of properties/methods shared between Tasks and TaskGroups
  var _default = _exports.default = _mixin.default.create({
    isRunning: (0, _computed.gt)('numRunning', 0),
    isQueued: (0, _computed.gt)('numQueued', 0),
    isIdle: (0, _object.computed)('isRunning', 'isQueued', function () {
      return !this.get('isRunning') && !this.get('isQueued');
    }),
    state: (0, _object.computed)('isRunning', 'isQueued', function () {
      if (this.get('isRunning')) {
        return 'running';
      } else if (this.get('isQueued')) {
        return 'queued';
      } else {
        return 'idle';
      }
    }),
    _propertyName: null,
    _origin: null,
    name: alias('_propertyName'),
    concurrency: alias('numRunning'),
    last: alias('_scheduler.lastStarted'),
    lastRunning: alias('_scheduler.lastRunning'),
    lastPerformed: alias('_scheduler.lastPerformed'),
    lastSuccessful: alias('_scheduler.lastSuccessful'),
    lastComplete: alias('_scheduler.lastComplete'),
    lastErrored: alias('_scheduler.lastErrored'),
    lastCanceled: alias('_scheduler.lastCanceled'),
    lastIncomplete: alias('_scheduler.lastIncomplete'),
    performCount: alias('_scheduler.performCount'),
    numRunning: 0,
    numQueued: 0,
    _seenIndex: 0,
    cancelAll: function cancelAll(options) {
      var _ref = options || {},
        reason = _ref.reason,
        resetState = _ref.resetState;
      reason = reason || ".cancelAll() was explicitly called on the Task";
      this._scheduler.cancelAll(reason);
      if (resetState) {
        this._resetState();
      }
    },
    group: (0, _object.computed)(function () {
      return this._taskGroupPath && (0, _object.get)(this.context, this._taskGroupPath);
    }),
    _scheduler: null,
    _resetState: function _resetState() {
      this.setProperties({
        'last': null,
        'lastRunning': null,
        'lastStarted': null,
        'lastPerformed': null,
        'lastSuccessful': null,
        'lastComplete': null,
        'lastErrored': null,
        'lastCanceled': null,
        'lastIncomplete': null,
        'performCount': 0
      });
    }
  });
});