define("ember-gridstack/modifiers/gs-item-attributes", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "ember-modifier", "@ember/string", "@ember/utils"], function (_exports, _slicedToArray2, _emberModifier, _string, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Copyright 2021, Yahoo Inc.
   * Copyrights licensed under the BSD License. See the accompanying LICENSE file for terms.
   */

  var GS_PREFIX = 'gs-';
  var _default = _exports.default = (0, _emberModifier.modifier)(function itemAttributes(element, opt) {
    var _ref = opt || [],
      _ref2 = (0, _slicedToArray2.default)(_ref, 1),
      _ref2$ = _ref2[0],
      options = _ref2$ === void 0 ? {} : _ref2$;
    Object.entries(options).forEach(function (_ref3) {
      var _ref4 = (0, _slicedToArray2.default)(_ref3, 2),
        key = _ref4[0],
        value = _ref4[1];
      var attribute = "".concat(GS_PREFIX).concat((0, _string.dasherize)(key));
      if ((0, _utils.isBlank)(value)) {
        element.removeAttribute(attribute);
      } else {
        element.setAttribute(attribute, value);
      }
    });
  });
});