define("ember-bootstrap/templates/components/bs-modal/header", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "1hEjjarK",
    "block": "[[[11,0],[24,0,\"modal-header\"],[17,1],[12],[1,\"\\n\"],[41,[49,[30,4]],[[[1,\"    \"],[18,4,[[28,[37,3],null,[[\"title\",\"close\"],[[50,[30,0,[\"titleComponent\"]],0,null,null],[50,[30,0,[\"closeComponent\"]],0,null,[[\"onClick\"],[[30,2]]]]]]]]],[1,\"\\n\"]],[]],[[[41,[48,[30,4]],[[[1,\"        \"],[18,4,null],[1,\"\\n\"]],[]],[[[1,\"        \"],[46,[30,0,[\"titleComponent\"]],null,null,[[\"default\"],[[[[1,[30,3]]],[]]]]],[1,\"\\n\"]],[]]],[41,[30,0,[\"closeButton\"]],[[[1,\"        \"],[46,[30,0,[\"closeComponent\"]],null,[[\"onClick\"],[[30,2]]],null],[1,\"\\n\"]],[]],null]],[]]],[13]],[\"&attrs\",\"@onClose\",\"@title\",\"&default\"],false,[\"if\",\"has-block-params\",\"yield\",\"hash\",\"component\",\"has-block\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-modal/header.hbs",
    "isStrictMode": false
  });
});