define("ember-bootstrap/templates/components/bs-form", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "VF8pA2M2",
    "block": "[[[11,\"form\"],[24,\"role\",\"form\"],[16,\"novalidate\",[30,0,[\"hasValidator\"]]],[16,0,[30,0,[\"layoutClass\"]]],[17,1],[4,[38,0],[[30,0],\"_element\"],null],[4,[38,1],[\"keypress\",[30,0,[\"handleKeyPress\"]]],null],[4,[38,1],[\"submit\",[30,0,[\"handleSubmit\"]]],null],[12],[1,\"\\n  \"],[18,2,[[28,[37,3],null,[[\"element\",\"group\",\"isSubmitting\",\"isSubmitted\",\"isRejected\",\"resetSubmissionState\",\"submit\"],[[50,[30,0,[\"elementComponent\"]],0,null,[[\"model\",\"formLayout\",\"horizontalLabelGridClass\",\"showAllValidations\",\"_disabled\",\"_readonly\",\"onChange\",\"_onChange\"],[[30,0,[\"model\"]],[30,0,[\"formLayout\"]],[30,0,[\"horizontalLabelGridClass\"]],[30,0,[\"showAllValidations\"]],[30,0,[\"disabled\"]],[30,0,[\"readonly\"]],[30,0,[\"elementChanged\"]],[30,0,[\"resetSubmissionState\"]]]]],[50,[30,0,[\"groupComponent\"]],0,null,[[\"formLayout\"],[[30,0,[\"formLayout\"]]]]],[30,0,[\"isSubmitting\"]],[30,0,[\"isSubmitted\"]],[30,0,[\"isRejected\"]],[30,0,[\"resetSubmissionState\"]],[30,0,[\"doSubmit\"]]]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"ref\",\"on\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-form.hbs",
    "isStrictMode": false
  });
});