define("ember-bootstrap/templates/components/bs-dropdown/menu", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "jsIretLP",
    "block": "[[[41,[30,0,[\"_isOpen\"]],[[[1,\"    \"],[8,[39,1],[[16,0,[29,[\"dropdown-menu \",[30,0,[\"alignClass\"]],\" \",[52,[30,0,[\"isOpen\"]],\"show\"],\" \",[30,1]]]],[24,\"tabindex\",\"-1\"],[17,2],[4,[38,2],[[30,3],\"menu\"],null],[4,[38,3],[[30,4],\"menu\"],null],[4,[38,4],[[30,0],\"menuElement\"],null]],[[\"@placement\",\"@popperTarget\",\"@renderInPlace\",\"@popperContainer\",\"@modifiers\",\"@onCreate\"],[[30,0,[\"popperPlacement\"]],[30,0,[\"toggleElement\"]],[30,0,[\"_renderInPlace\"]],[30,0,[\"destinationElement\"]],[30,0,[\"popperModifiers\"]],[30,0,[\"setFocus\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[18,5,[[28,[37,6],null,[[\"item\",\"link-to\",\"linkTo\",\"divider\"],[[50,[30,0,[\"itemComponent\"]],0,null,null],[50,[30,0,[\"linkToComponent\"]],0,null,null],[50,[30,0,[\"linkToComponent\"]],0,null,null],[50,[30,0,[\"dividerComponent\"]],0,null,null]]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"@class\",\"&attrs\",\"@registerChildElement\",\"@unregisterChildElement\",\"&default\"],false,[\"if\",\"ember-popper\",\"did-insert\",\"will-destroy\",\"ref\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-bootstrap/templates/components/bs-dropdown/menu.hbs",
    "isStrictMode": false
  });
});