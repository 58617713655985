define("ember-bootstrap/utils/cp/size-class", ["exports", "@ember/object", "@ember/utils", "@ember/debug"], function (_exports, _object, _utils, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = sizeClass;
  function sizeClass(prefix, sizeProperty) {
    (true && !(typeof prefix === 'string') && (0, _debug.assert)('You have to provide a prefix for sizeClass', typeof prefix === 'string'));
    (true && !(typeof sizeProperty === 'string') && (0, _debug.assert)('You have to provide a sizeProperty for sizeClass', typeof sizeProperty === 'string'));
    return (0, _object.computed)('size', function () {
      var size = this.get(sizeProperty);
      (true && !(!size || typeof size === 'string' && size !== '') && (0, _debug.assert)('The value of `size` must be a string', !size || typeof size === 'string' && size !== ''));
      return (0, _utils.isBlank)(size) ? null : "".concat(prefix, "-").concat(size);
    });
  }
});