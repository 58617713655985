define("ember-drag-sort/components/drag-sort-item", ["exports", "@ember/component", "@ember/debug", "@ember/service", "@ember/object/computed", "@ember/object", "@ember/runloop", "ember-drag-sort/templates/components/drag-sort-item"], function (_exports, _component, _debug, _service, _computed, _object, _runloop, _dragSortItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // ----- Ember modules -----

  // ----- Own modules -----

  function getComputedStyleInt(element, cssProp) {
    var computedStyle = window.getComputedStyle(element, null);
    var valueStr = computedStyle.getPropertyValue(cssProp);
    return parseInt(valueStr, 10);
  }
  var _default = _exports.default = _component.default.extend({
    // ----- Arguments -----
    item: undefined,
    index: undefined,
    items: undefined,
    group: undefined,
    childTagName: 'div',
    draggingEnabled: undefined,
    handle: null,
    isHorizontal: false,
    isRtl: false,
    sourceOnly: false,
    dragEndAction: undefined,
    dragStartAction: undefined,
    determineForeignPositionAction: undefined,
    // ----- Services -----
    dragSort: (0, _service.inject)(),
    // ----- Overridden properties -----
    layout: _dragSortItem.default,
    classNameBindings: [':dragSortItem', 'isDragged2:-isDragged', 'isDraggingOver:-isDraggingOver', 'shouldShowPlaceholderBefore2:-placeholderBefore', 'shouldShowPlaceholderAfter2:-placeholderAfter', 'isTarget:-isTarget:-isTargetNOT', 'index', 'targetIndex'],
    attributeBindings: ['draggable'],
    // ----- Static properties -----
    isDragged2: false,
    originalHeight: null,
    shouldShowPlaceholderBefore2: undefined,
    shouldShowPlaceholderAfter2: undefined,
    isVertical: (0, _computed.not)('dragSort.isHorizontal'),
    // ----- Aliases -----
    isDraggingUp: (0, _computed.reads)('dragSort.isDraggingUp'),
    sourceList: (0, _computed.reads)('dragSort.sourceList'),
    sourceIndex: (0, _computed.reads)('dragSort.sourceIndex'),
    targetIndex: (0, _computed.reads)('dragSort.targetIndex'),
    targetList: (0, _computed.reads)('dragSort.targetList'),
    // ----- Computed properties -----
    draggable: (0, _object.computed)('draggingEnabled', 'handle', function () {
      var handle = this.get('handle');
      var draggingEnabled = this.get('draggingEnabled');
      return !handle && draggingEnabled ? true : null;
    }),
    isDragged: (0, _object.computed)('dragSort.isDragging', 'items', 'sourceList', 'index', 'sourceIndex', function () {
      var isDragging = this.get('dragSort.isDragging');
      var items = this.get('items');
      var sourceList = this.get('sourceList');
      var index = this.get('index');
      var sourceIndex = this.get('sourceIndex');
      return isDragging && items === sourceList && index === sourceIndex;
    }),
    isDraggingOver: (0, _object.computed)('dragSort.isDragging', 'items', 'targetList', 'index', 'targetIndex', 'isDragged', 'sourceOnly', function () {
      var isDragging = this.get('dragSort.isDragging');
      var items = this.get('items');
      var targetList = this.get('targetList');
      var index = this.get('index');
      var targetIndex = this.get('targetIndex');
      var isDragged = this.get('isDragged');
      var sourceOnly = this.get('sourceOnly');
      return !sourceOnly && isDragging && items === targetList && index === targetIndex && !isDragged;
    }),
    isLast: (0, _object.computed)('index', 'items.[]', function () {
      var index = this.get('index');
      var count = this.get('items.length');
      return index === count - 1;
    }),
    shouldShowPlaceholderBefore: (0, _object.computed)('isDraggingOver', 'isDraggingUp', 'sourceOnly', function () {
      var isDraggingOver = this.get('isDraggingOver');
      var isDraggingUp = this.get('isDraggingUp');
      var sourceOnly = this.get('sourceOnly');
      return !sourceOnly && isDraggingOver && isDraggingUp;
    }),
    shouldShowPlaceholderAfter: (0, _object.computed)('isDraggingOver', 'isDraggingUp', 'sourceOnly', function () {
      var isDraggingOver = this.get('isDraggingOver');
      var isDraggingUp = this.get('isDraggingUp');
      var sourceOnly = this.get('sourceOnly');
      return !sourceOnly && isDraggingOver && !isDraggingUp;
    }),
    // ----- Overridden methods -----
    didInsertElement: function didInsertElement() {
      // Consume properties for observers to act
      this.getProperties('shouldShowPlaceholderBefore', 'shouldShowPlaceholderAfter');
    },
    dragStart: function dragStart(event) {
      // Ignore irrelevant drags
      if (!this.get('draggingEnabled')) return;
      if (!this.isHandleUsed(event.target)) {
        event.preventDefault();
        return;
      }
      event.stopPropagation();

      // Required for Firefox. http://stackoverflow.com/a/32592759/901944
      if (event.dataTransfer) {
        if (event.dataTransfer.setData) event.dataTransfer.setData('text', '');
        if (event.dataTransfer.setDragImage) event.dataTransfer.setDragImage(this.element, 0, 0);
      }
      var dragStartAction = this.get('dragStartAction');
      if (dragStartAction) {
        var element = this.get('element');
        var item = this.get('item');
        dragStartAction({
          event: event,
          element: element,
          draggedItem: item
        });
      }
      this.startDragging(event);
    },
    dragEnd: function dragEnd(event) {
      // Ignore irrelevant drags
      if (!this.get('dragSort.isDragging')) return;
      event.stopPropagation();
      event.preventDefault();
      this.endDragging(event);
    },
    // Required for Firefox. http://stackoverflow.com/a/32592759/901944
    drop: function drop(event) {
      event.preventDefault();
    },
    dragOver: function dragOver(event) {
      // Ignore irrelevant drags
      if (!this.get('dragSort.isDragging') || this.get('determineForeignPositionAction') || this.get('sourceOnly')) return;
      var group = this.get('group');
      var activeGroup = this.get('dragSort.group');
      if (group !== activeGroup) return;
      event.stopPropagation();
      event.preventDefault();
      this.draggingOver(event);
    },
    dragEnter: function dragEnter(event) {
      if (!this.get('dragSort.isDragging')) return;
      // Without this, dragOver would not fire in IE11. http://mereskin.github.io/dnd/
      event.preventDefault();
    },
    // ----- Custom methods -----
    startDragging: function startDragging() {
      this.collapse();
      var additionalArgs = this.get('additionalArgs');
      var item = this.get('item');
      var index = this.get('index');
      var items = this.get('items');
      var group = this.get('group');
      var dragSort = this.get('dragSort');
      var isHorizontal = this.get('isHorizontal');
      dragSort.startDragging({
        additionalArgs: additionalArgs,
        item: item,
        index: index,
        items: items,
        group: group,
        isHorizontal: isHorizontal
      });
    },
    endDragging: function endDragging() {
      this.restore();
      var action = this.get('dragEndAction');
      var dragSort = this.get('dragSort');
      dragSort.endDragging({
        action: action
      });
    },
    draggingOver: function draggingOver(event) {
      var sourceOnly = this.get('sourceOnly');
      if (sourceOnly) {
        event.preventDefault();
        return;
      }
      var group = this.get('group');
      var index = this.get('index');
      var items = this.get('items');
      var element = this.get('element');
      var isHorizontal = this.get('dragSort.isHorizontal');
      var isRtl = this.get('isRtl') && isHorizontal;
      var isPlaceholderBefore = this.get('shouldShowPlaceholderBefore2');
      var isPlaceholderAfter = this.get('shouldShowPlaceholderAfter2');
      var dragSort = this.get('dragSort');
      var placeholderModifier = isRtl ? -1 : 1;
      var beforeAttribute = 'padding-top';
      var afterAttribute = 'padding-bottom';
      if (isHorizontal) {
        beforeAttribute = isRtl ? 'padding-right' : 'padding-left';
        afterAttribute = isRtl ? 'padding-left' : 'padding-right';
      }
      var placeholderCorrection = isPlaceholderBefore ? getComputedStyleInt(element, beforeAttribute) * placeholderModifier : isPlaceholderAfter ? -getComputedStyleInt(element, afterAttribute) * placeholderModifier : 0; // eslint-disable-line indent

      var offset = isHorizontal ? element.getBoundingClientRect().left : element.getBoundingClientRect().top;
      var itemSize = isHorizontal ? element.offsetWidth : element.offsetHeight;
      var mousePosition = isHorizontal ? event.clientX : event.clientY;
      var isDraggingUp = isRtl ? mousePosition - offset > (itemSize + placeholderCorrection) / 2 : mousePosition - offset < (itemSize + placeholderCorrection) / 2;
      dragSort.draggingOver({
        group: group,
        index: index,
        items: items,
        isDraggingUp: isDraggingUp
      });
    },
    collapse: function collapse() {
      var _this = this;
      // The delay is necessary for HTML classes to update with a delay.
      // Otherwise, dragging is finished immediately.
      (0, _runloop.next)(function () {
        if (_this.get('isDestroying') || _this.get('isDestroyed')) return;
        _this.set('isDragged2', true);
      });
    },
    restore: function restore() {
      var _this2 = this;
      // The delay is necessary for HTML class to update with a delay.
      // Otherwise, dragging is finished immediately.
      (0, _runloop.next)(function () {
        if (_this2.get('isDestroying') || _this2.get('isDestroyed')) return;
        _this2.set('isDragged2', false);
      });
    },
    isHandleUsed: function isHandleUsed(target) {
      var handle = this.get('handle');
      var element = this.get('element');
      if (!handle) return true;
      var handleElement = element.querySelector(handle);
      (true && !(!!handleElement) && (0, _debug.assert)('Handle not found', !!handleElement));
      return handleElement === target || handleElement.contains(target);
    },
    // ----- Observers -----
    setPlaceholderBefore: (0, _object.observer)('shouldShowPlaceholderBefore', function () {
      var _this3 = this;
      // The delay is necessary for HTML class to update with a delay.
      // Otherwise, dragging is finished immediately.
      (0, _runloop.next)(function () {
        if (_this3.get('isDestroying') || _this3.get('isDestroyed')) return;
        _this3.set('shouldShowPlaceholderBefore2', _this3.get('shouldShowPlaceholderBefore'));
      });
    }),
    setPlaceholderAfter: (0, _object.observer)('shouldShowPlaceholderAfter', function () {
      var _this4 = this;
      // The delay is necessary for HTML class to update with a delay.
      // Otherwise, dragging is finished immediately.
      (0, _runloop.next)(function () {
        if (_this4.get('isDestroying') || _this4.get('isDestroyed')) return;
        _this4.set('shouldShowPlaceholderAfter2', _this4.get('shouldShowPlaceholderAfter'));
      });
    })
  });
});